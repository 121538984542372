<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>
          <span class="rollback" @click="rollback"
            ><i class="fas fa-arrow-circle-left"></i></span
          >{{ $t("scheduleAttEmp.title") }}
        </h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field
          class="btn btn-color btn-border btn-border-color"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('Search.search')"
          v-model="searchItem"
          @keypress.enter="searchFilterItem"
        >
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("scheduleAttEmp.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listAttractEmployee.length > 0">
          <thead>
            <tr>
              <th class="text-center">
                {{ $t("scheduleAttEmp.no") }}
              </th>
              <th class="text-center">
                {{ $t("scheduleAttEmp.image") }}
              </th>

              <th class="text-left">
                {{ $t("scheduleAttEmp.name") }}
              </th>
              <th class="text-left">
                {{ $t("scheduleAttEmp.email") }}
              </th>
              <th class="text-left">
                {{ $t("scheduleAttEmp.empType") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listAttractEmployee" :key="idx">
              <td>
                {{ pagination.current_page * 10 - 10 + idx + 1 }}
              </td>
              <td>
                <div class="image-table" v-if="item.urlProfile">
                  <img :src="item.urlProfile" alt="" />
                </div>
                <div class="image-table" v-else>
                  <i class="fal fa-user"></i>
                </div>
              </td>

              <td>
                <div>
                  {{ item.name }} <span>{{ item.surname }}</span>
                  <div v-if="item.joinPositions.length" class="position">
                    <span>
                      {{ item.joinPositions[0].name }}
                      <span
                        v-if="item.joinPositions.length > 1"
                        class="length-position"
                        @click="showModalItemPosition(item)"
                        >+{{ item.joinPositions.length - 1 }}</span
                      >
                    </span>
                  </div>
                </div>
              </td>

              <td v-if="item && item.joinUser">{{ item.joinUser.email }}</td>

              <td>
                <div v-if="item.employment_type === 'FullTime'">
                  <span class="fullTime">FullTime</span>
                </div>
                <span class="partTime" v-else>PartTime</span>
              </td>

              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title
                        class="btn-delete"
                        @click="onDeleted(item)"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>

        <default-table-no-result v-else />

        <showItemPosition
          :visible="modalShowItemPosition"
          @success="fetchAttractEmployee"
          :item="itemsPosition"
          @change="(val) => (modalShowItemPosition = val)"
        />

        <ModalDelete>
          <template v-slot="{ close }">
            <Delete
              :item="item"
              @close="close"
              @success="fetchAttractEmployee()"
            />
          </template>
        </ModalDelete>
        <Pagination
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchAttractEmployee"
        >
        </Pagination>
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import Delete from "@/views/MainCompany/scheduleAttractEmployee/Delete";
import showItemPosition from "./showItemPosition";
import defaultTableNoResult from "@/components/defaultTableNoResult";

export default {
  components: {
    Loading,
    Pagination,
    Delete,
    showItemPosition,
    defaultTableNoResult,
  },

  data() {
    return {
      modalShowItemPosition: false,
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,
      isLoading: true,
      listAttractEmployee: [],
      item: {},
      itemsPosition: {},
    };
  },
  methods: {
    rollback() {
      this.$router.push({
        name: "schedule.index",
      });
    },
    showModalItemPosition(item) {
      this.itemsPosition = item;
      this.modalShowItemPosition = true;
    },

    searchFilterItem() {
      this.pagination.current_page = 1;
      this.fetchAttractEmployee();
    },
    onCreate() {
      this.$router
        .push({ name: "scheduleAttractEmployee.create" })
        .catch(() => {});
    },

    onDeleted(item) {
      this.item = item;
      this.$store.commit("modalDelete_State", true);
    },

    fetchAttractEmployee() {
      this.isLoading = true;
      const id = this.$route.params.schedule_id;
      this.$axios
        .get(`company/check-in-schedule/${id}/all`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.listAttractEmployee = res.data.data.data;
            console.log(this.listAttractEmployee);
            this.pagination = res.data.data.pagination;
            if (!this.listAttractEmployee.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchAttractEmployee();
  },
};
</script>

<style scoped lang="scss">
.image-table {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 2px;
  background-color: rgba(30, 144, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    object-fit: cover;
  }

  i {
    //color: #719AFF;
    font-size: 24px;
    color: rgba(30, 144, 255, 0.4);
  }
}

.position {
  span {
    position: relative;
    border-radius: 30px;
    font-size: 14px;
    // padding: 3px 18px;
    font-family: $font-family;
    letter-spacing: 1px;
    text-transform: capitalize;
    //background-color: rgba(30, 144, 255,0.4);
    color: #d70f64;

    .length-position {
      position: absolute;
      font-size: 12px;
      padding: 0.5px 6px;
      right: -35px;
      top: 0px;
      color: white;
      background-color: rgba(30, 144, 255, 0.4);
      cursor: pointer;
      transition: ease-out 0.2s;
    }

    .length-position:hover {
      transform: translateY(-10%);
    }
  }
}

.fullTime {
  background-color: rgba(123, 237, 159, 0.4);
  font-family: $font-family;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 1px 8px;
  border-radius: 20px;
}

.partTime {
  background-color: rgba(255, 165, 2, 0.4);
  font-family: $font-family;
  font-size: 12px;
  letter-spacing: 1px;
  padding: 1px 8px;
  border-radius: 20px;
}
</style>
