<template>
  <v-dialog
      v-model="modalShowItemPosition"
      width="500"
      persistent
      @close="setModalShowItemPosition(false)"
      transition="scroll-x-transition">
    <v-card class="main-card-delete">
      <v-card-actions class="card-action-delete" style="float: right;">
        <v-btn

            icon
            depressed
            @click="setModalShowItemPosition(false)"
        >
          <v-icon class="icon-style">{{ icon }}</v-icon>
        </v-btn>
      </v-card-actions>
      <div class="from-content">
        <div class="image--employee">
          <div class="image--employee-left">
            <div class="image-circle-employee">
              <div class="img" v-if="item.urlProfile">
                <img :src="item.urlProfile" alt="">
              </div>

              <div class="img" v-else>
                <i class="fal fa-user"></i>
              </div>
              <div class="small-circle"></div>
            </div>
          </div>
          <div class="image--employee-right" v-if="item && item.joinAddress">
            <div class="emp-name">
              <h1>{{ item.name }} <span>{{ item.surname }}</span></h1>
              <h2><span><i class="fal fa-envelope-open-text"></i></span>{{ item.joinUser.email }}</h2>
            </div>
          </div>

        </div>
        <div class="employee-show_detail">
          <div class="show-detail-left">
            <span class="title-icon"><span class="icon-color"><i class="fas fa-briefcase"></i></span> Position:</span>
            <ul v-for="(position,idx) in item.joinPositions" :key="idx">
              <li class="position-detail">
                <span ><i class="far fa-circle sm-icon-color"></i></span> {{ position.name }}

              </li>
            </ul>
            <p class="title-icon"><span class="icon-color"><i class="fas fa-paper-plane"></i></span> Contact:</p>
            <ul>
              <li><span><i class="far fa-circle sm-icon-color"></i></span>facebook</li>
              <li><span><i class="far fa-circle sm-icon-color"></i></span>whats app</li>
              <li><span><i class="far fa-circle sm-icon-color"></i></span>phone</li>
            </ul>
            <p class="title-icon"><span class="icon-color"><i class="fas fa-map-marked-alt"></i></span> Address:</p>
            <ul v-if="item && item.joinAddress">
              <li><span><i class="far fa-circle sm-icon-color"></i></span>village: {{item.joinAdress.village}}</li>
              <li><span><i class="far fa-circle sm-icon-color"></i></span>district: {{item.joinAdress.district_name}}</li>
              <li><span><i class="far fa-circle sm-icon-color"></i></span>Province:{{item.joinAdress.province_name}}</li>
            </ul>
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    visible: {
      default: false,
    },
    item: {},
    icon: {
      default: 'mdi-close',
      type: String
    },
  },
  data() {
    return {
      itemsEmployees: {},
      modalShowItemPosition: false,
    }
  },

  methods: {
    setModalShowItemPosition(isVisible) {
      this.modalShowItemPosition = isVisible;
      this.$emit('change', isVisible);
    }
  },
  watch: {
    visible(val) {
      this.modalShowItemPosition = val;
    },
    item(val) {
      console.log(val)
    }
  },
  created() {
    console.log(this.item)
  }
}
</script>

<style scoped lang="scss">

.from-content {
  width: 100%;
  height: 600px;
  background-color: white;
  box-shadow: 0 2px 4px 1px rgb(0 0 0 / 12%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5px 20px;


  .image--employee {
    width: 100%;
    height: 120px;
    // background-color: red;
    display: flex;
    margin-top: 20px;

    .image--employee-left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      // background-color: yellow;

      .image-circle-employee {
        width: 120px;
        height: 120px;
        background-color: rgba(116, 125, 140, 0.4);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .img {
          width: 115px;
          height: 115px;
          background-color: rgba(223, 228, 234, 0.6);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 45px;
            color: rgba(223, 228, 234, 1);
          }

          img {
            width: 110px;
            height: 110px;
            border-radius: 50%;
            object-fit: cover;

          }
        }

        .small-circle {
          position: absolute;
          right: 5px;
          width: 20px;
          height: 20px;
          bottom: 10px;
          background-color: rgba(112, 161, 255, 1);
          border-radius: 50%;
        }
      }
    }

    .image--employee-right {
      flex: 2;
      //background-color: red;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 20px;

      .emp-name {
        width: 100%;
        height: auto;
        //background-color: yellow;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 15px;

        h1 {
          font-family: $font-family;
          font-size: 22px;
          text-transform: capitalize;
          font-weight: 500;
        }

        h2 {
          font-size: 18px;

          & span {
            margin-right: 10px;
            color: $main-btn-color;
          }
        }
      }
    }


  }

  .from {
    width: 100%;
    height: 350px;
    padding: 20px 40px;
  }

  .employee-show_detail {
    // background-color: yellow;
    height: 800px;
    width: 100%;
    display: flex;
   // background-color: yellow;
    justify-content: center;
    align-items: center;

    .show-detail-left {
      height: auto;
      padding: 20px;
      border-radius: 8px;
      width:400px;
      display: flex;
    //  background-color: rgba(0, 210, 211,0.2);
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      margin-bottom: 80px;
      //background-color: red;
      font-family: $font-family;
      text-transform: capitalize;
      letter-spacing: 1px;
      .position-detail{
        font-size: 14px;
      }
      .title-icon {
        font-weight: 500;
        letter-spacing: 1px;
        border: 0;
        padding: 0;
        margin: 0;
      }

      .sm-icon-color{
        font-size: 12px;
        color: #18BBEC;
        margin-bottom: 10px;
        margin-right: 2px;
      }
      .icon-color {
        color: #18BBEC;
        font-size: 18px;
      }
    }

  }
}

.title-header-form {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 24px;
  letter-spacing: 1px;
  color: #4A4A4A;
}
</style>
